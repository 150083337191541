<template>
  <div class="tw-w-full tw-overflow-x-auto tw-util-scrollbar">
    <div
      ref="target"
      class="tw-flex tw-my-3 tw-space-x-5 md:tw-space-x-10 tw-w-fit"
    >
      <component
        :is="option.to ? 'router-link' : 'button'"
        v-for="(option, index) in options"
        :key="index"
        :to="option.to || null"
        class="tw-flex tw-justify-center tw-items-center tw-font-bold tw-relative tw-outline-none tw-text-h6 tw-leading-tight tw-whitespace-nowrap tw-button-transition tw-group"
        :tabindex="isActive(index) ? '-1' : '0'"
        @click="updateTab($event, option)"
      >
        <span :class="linkClass(index)">{{ option.text }}</span>
        <div
          v-if="option.count"
          class="tw-flex tw-justify-center tw-items-center game-primary-button-color game-primary-text tw-font-bold tw-text-sm tw-ml-1 tw-h-5 tw-w-5 tw-rounded-full tw-translate-y-px"
        >
          {{ option.count }}
        </div>
        <div
          class="tw-absolute tw-inset-x-0 tw-h-0.5 game-primary-button-color tw-bottom-[-0.875rem]"
          :class="isActive(index) ? 'tw-opacity-100' : 'tw-opacity-0'"
        />
      </component>
    </div>
    <div class="tw-h-0.5" style="background: #181818" />
  </div>
</template>

<script>
import { toRefs, ref, watchEffect } from 'vue'
export default {
  props: {
    modelValue: {
      type: [String, Number],
      default: null,
    },
    options: {
      type: Array,
      required: true,
    },
  },
  emits: ['input'],
  setup(props, { emit }) {
    const { modelValue, options } = toRefs(props)
    const target = ref(null)

    function isActive(index) {
      return (
        options.value.findIndex((o) => o.value === modelValue.value) === index
      )
    }

    function linkClass(index) {
      if (isActive(index)) {
        return ''
      }
      return 'hover:game-link-color-hover focus:game-link-color-hover tw-opacity-75 hover:tw-opacity-100'
    }

    function updateTab(e, option) {
      emit('input', e, option.value)
    }

    watchEffect(() => {
      if (target.value && options.value?.length) {
        for (let i in options.value) {
          if (isActive(i)) {
            target.value.children[i]?.scrollIntoView({
              behavior: 'smooth',
              block: 'nearest',
              inline: 'center',
            })
            return
          }
        }
      }
    })

    return {
      linkClass,
      updateTab,
      isActive,
      target,
    }
  },
}
</script>
